import { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { GetServerSideProps } from 'next';
import Image from 'next/image';
import Link from 'next/link';
// import { useRouter } from 'next/router';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import logo from '@public/assets/logo.svg';
import { Button, Typography } from 'antd';
import * as Comlink from 'comlink';
import { Container, Row } from 'src/shared/components/layout';
import styled from 'styled-components';

import { useAuth } from '@/_pages/auth/hooks/useAuth';
import { DebugTools } from '@/_pages/layouts/DebugTools';
import MainPageForExportedMode from '@/_pages/MainPageForExportedMode';
import { ContainerBG } from '@/shared/components';
import { COLORS } from '@/shared/constants/colors';
import { isExported, SSO_AUTH_URL } from '@/shared/constants/constants';
// import { useIsPwa } from '@/services/hooks/use-is-pwa';
import { useRegistrationStore } from '@/shared/store/registration.store';
import { Title } from '@/shared/text';
import { popupCenter } from '@/shared/utils/window';

import appInfo from '../../app-info.json';
import { IWorker } from '../../worker';

export default isExported ? MainPageForExportedMode : IndexPage;

function IndexPage({
  beneficiaryJWT: propsBeneficiaryJWT,
  delegationRequestId: delegationRequestId,
}: {
  beneficiaryJWT: string | undefined;
  delegationRequestId: string | undefined;
}): ReactNode {
  // const isPwa = useIsPwa();
  // const { push } = useRouter();
  const { setBeneficiaryJWT } = useRegistrationStore();
  const { t } = useTranslation('welcome');
  useAuth();

  useEffect(() => {
    setBeneficiaryJWT(propsBeneficiaryJWT);
  }, []);

  useEffect(() => {
    (async () => {
      // sleep 2 seconds
      await new Promise((resolve) => setTimeout(resolve, 1000));
      const worker = new Worker(
        new URL('../../worker/index.ts', import.meta.url),
      );
      // const worker = new Worker('/worker-development.js');
      const obj = Comlink.wrap<IWorker>(worker);
      await obj.echo('Hello world from main thread!');
    })();
  }, []);

  return (
    <RowStyled justifyContent={'center'}>
      <Container maxWidth={390} width={'100%'} paddingY={10}>
        <Row direction="column" alignCenter height="100%">
          <Container paddingX={30} width="100%">
            <Image src={logo} alt="logo" width={150} height={96} />
          </Container>

          <Container marginTop={32}>
            <ContainerBG>
              <Container paddingX={40} paddingY={40}>
                <Title level={2}>{t('welcome:welcome.welcome')}</Title>

                <Container marginTop={4}>
                  <Typography.Title level={5}>
                    {t('welcome:welcome.title')}
                  </Typography.Title>
                </Container>

                <Container marginTop={24}>
                  <Typography.Text>
                    {/*<ul>*/}
                    {t('welcome:welcome.text_1')}
                    {/*<li>{t('welcome:welcome.text_2')}</li>*/}
                    {/*</ul>*/}
                  </Typography.Text>
                </Container>

                <Container marginTop={76}>
                  {delegationRequestId ? (
                    <Link
                      href={{
                        pathname: '/auth/login/assistant',
                        query: {
                          delegationRequestId: delegationRequestId,
                        },
                      }}
                      data-testid={'loginBtn'}
                    >
                      <Button block size="large">
                        {t('welcome:welcome.log_in_button')}
                      </Button>
                    </Link>
                  ) : propsBeneficiaryJWT ? (
                    <Link href={'/auth/register'}>
                      <Button block size="large">
                        {t('welcome:welcome.signup_button')}
                      </Button>
                    </Link>
                  ) : (
                    <>
                      <Button
                        block
                        size="large"
                        onClick={() => popupCenter({ url: SSO_AUTH_URL })}
                        style={{ marginBottom: '20px' }}
                      >
                        {t('welcome:welcome.log_in_button')}
                      </Button>
                      <Link href={'/auth/login'}>
                        <Button block size="large">
                          {t('welcome:welcome.log_in_next_owner_button')}
                        </Button>
                      </Link>
                    </>
                  )}
                </Container>

                {/*{!isPwa && (*/}
                {/*  <Container width="100%" marginTop={24}>*/}
                {/*    <Link href={'/auth/register'}>*/}
                {/*      <Button block type="primary" size="large">*/}
                {/*        {t('welcome:welcome.signup_button')}*/}
                {/*      </Button>*/}
                {/*    </Link>*/}
                {/*  </Container>*/}
                {/*)}*/}

                {/*<Container marginTop={24} width="100%" textCenter>*/}
                {/*  <Typography.Link*/}
                {/*    underline*/}
                {/*    onClick={() =>*/}
                {/*      push({*/}
                {/*        pathname: '/auth/login/assistant',*/}
                {/*        query: { delegationRequestId: delegationRequestId },*/}
                {/*      })*/}
                {/*    }*/}
                {/*  >*/}
                {/*    {t('welcome.loginAsAssistantButton')}*/}
                {/*  </Typography.Link>*/}
                {/*</Container>*/}
              </Container>
            </ContainerBG>
          </Container>
          <Container marginTop={24} width="100%" textCenter>
            <Typography.Text style={{ opacity: 0.1 }}>
              v {appInfo.version}
            </Typography.Text>
          </Container>
        </Row>
      </Container>
      <DebugTools />
    </RowStyled>
  );
}

export const getServerSideProps: GetServerSideProps = async ({
  query,
  locale,
}) => {
  const _delegationRequestId = query.delegationRequestId;
  const delegationRequestId = Array.isArray(_delegationRequestId)
    ? _delegationRequestId[0]
    : _delegationRequestId;

  if (!delegationRequestId) {
    return {
      props: {
        beneficiaryJWT: (query?.beneficiarydata as string) || null,
        ...(await serverSideTranslations(locale ?? 'en', [
          'welcome',
          'signup',
          'auth',
        ])),
      },
    };
  }
  return {
    props: {
      delegationRequestId: delegationRequestId,
      beneficiaryJWT: (query?.beneficiarydata as string) || null,
      ...(await serverSideTranslations(locale ?? 'en', ['welcome', 'signup'])),
    },
  };
};

const RowStyled = styled(Row)`
  height: 100dvh;
  background-color: ${COLORS.colorBgLayout};
`;
